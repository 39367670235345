<template>
  <div id="event" >
    <v-slide-y-transition mode="out-in">
      <v-layout column >
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column v-if="loading" :style="windowWidth < 770 ? '' : 'margin-left:5%;margin-right:5%'">

         <v-layout column >
             <v-layout >
               <v-skeleton-loader
                 class="mx-auto"
                 type="image"
                 width="100%"
                 height="350"
               ></v-skeleton-loader>
             </v-layout >
          </v-layout>
        </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

         <v-layout column v-else style="margin-top:-64px">
           <!-- v-show="!loadingData" -->
           <v-layout column align-center>
               <v-card
                 style="border-radius:0px"
                 :dark="dark"
                 :min-width="windowWidth < 768 ? '' : '100%'"
                 :max-width="windowWidth < 768 ? '' : '100%'"
                 :min-height="windowHeight"
               >
                 <v-img
                   src="/img/app/mcc_banner.jpeg"
                   class="align-end"
                   :min-height="windowWidth < 770 ? '120' : '350'"
                   :max-height="windowWidth < 770 ? '175' : '350'"
                 >
                 </v-img>
                 <p style="margin-top:15px;font-size:25px" class="text-center font-weight-bold">Thank you for authenticating your {{ getNftName }}.</p>
                 <p style="margin-top:25px;font-size:20px" class="text-center font-weight-medium">The Mola Chill 2303 event will take place at:<br>26 Leake Street. 7 Addington St, London SE1 7RY</p>
                 <p style="margin-top:25px;font-size:20px" class="text-center font-weight-medium">Door opens on 23 March, 2022 at 6PM.<br>Please show your Mola NFT at the door using an OpenSea mobile app.</p>
               </v-card>
             </v-layout>

        </v-layout>
       </v-layout>
    </v-slide-y-transition>

    <!-- ############################################ DIALOGS ################################################# -->

  </div>
</template>

<script>
import store from '@/store/index'
import { db } from '@/main'
export default {
  name: 'event',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number
  },
  data () {
    return {
      lang: this.$store.state.lang,
      loading: true,
      showBottomNav: false,
      signUpDialog: false,
      videoDialog: false,
      signUpView: 1,
      installMM: false,
      carousel: 0,
      count: 0,
      show: false,
      startDelay1: 5000,
      startDelay2: 5000,
      resultInterval: null,
      countDown: 8,
      toggle: 'left',
    }
  },
  components: {
  },
  watch: {
    windowWidth () {
      if (this.windowWidth < 768) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
    }
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    getUser () {
      return this.$store.state.user
    },
    getOwnsNFTs () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.user.ownsNFTs
    },
    getNftName () {
      return this.$store.state.user.nftName
    }
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
    if (!this.getOwnsNFTs) {
      this.$router.push('/')
    }
  },
  created () {
    store.commit('ShowNav')
    // store.commit('ShowNavHome')
    this.init()
  },
  methods: {
    init () {
      setTimeout(() => {
        this.show = true
        this.loading = false
        var obj = {
          owns_nft: true,
        }
        this.saveSettingsData(obj)
      }, 2000)
    },
    saveSettingsData (obj) {
      db.collection('users').doc(this.getUser.docId).update(obj)
        .then(() => {
            // console.log('User Account in bucket updated')
          })
        .catch(error => {
            console.log(error)
          })
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      // console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .wrapperDark{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrapperLight{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrap-text {
    word-break: normal;
  }
</style>
